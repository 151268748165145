* {
    padding: 0;
    margin: 0;
}

#banner {
    background-image: url("../../res/banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 25vw;
    min-height: 200px;

    padding-top: 8%

}

.subtitle {
    margin: 0;
    padding: 0;

    color: white;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 2.5vw;

}

.title {
    margin: 0;
    padding: 0;

    color: white;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 3.5vw;
}

.text {
    margin: 0;
    padding: 0;

    color: white;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 1.5vw;
}

.banner-buttons {
    background-color: white;
    border: none;
    border-radius: 5px;
    margin: 1vh;
    outline: none;
    width: 200px;
    height: 50px;
}

.button-container {
    margin: 35px;
    display: flex;
    justify-content: center;
}

.section-header {
    visibility: visible;
}

#podium-container {
    /* display: flex; */
    height: 20vh;
    /* justify-content: baseline; */
    bottom: 0;
}

#standings {
    height: 35vh;
    width: 15vw;
    min-width: 150px;
}

#podium-container {
    height: 30vh;
    width: 15vw;
    min-width: 150px;
}

.podium {
    bottom: 0;
    min-width: 50px;
}

#p2nd-place {
    float: left;
    bottom: 0;
}

#p1st-place {
    float: left;
    bottom: 0;
}

#p3rd-place {
    float: left;
    bottom: 0;
}

.standings-text {
    color: white;
    font-size: 30px;
    line-height: 2.5vh;

    text-align: center;
}

#name-container-1 {
    height: 5vh;
    width: 5vw;
    min-width: 50px;
}

#name-container-2 {
    height: 10vh;
    width: 5vw;
    min-width: 50px;
}

#name-container-3 {
    height: 15vh;
    width: 5vw;
    min-width: 50px;
}

#s1p {
    background: #5F00D8;
    height: 25vh;
    margin: 0;
}

#s2p {
    background: #45009C;
    height: 20vh;
    margin: 0;
}

#s3p {
    background: #46008B;
    height: 15vh;
    margin: 0;
}

.standings-name {
    margin: 0;
    text-align: center;
    font-size: 15px;
    vertical-align: bottom;
    line-height: 2.5vh;
}

#spacer-1 {
    height: 2.5vh;
}

#spacer-2 {
    height: 7.5vh;
}

#spacer-3 {
    height: 12.5vh;
}

#sections {
    width: 72vw;;
    height: 45vh;
}

.section {
    float: left;
    margin: 2.5vw;
}

.standings-spacer {
    height: 2.5vh;
    width: 15vw;
}

#standings-section {
    width: 15vw;
    min-width: 150px;
}

#previous-events-section {
    width: 45vw;
    min-width: 450px;
}

.separator {
    height: 1px;
    background: black;
}

.previous-event-card {
    float: left;
    margin-left: 1.25vw;
    margin-right: 1.25vw;
    margin-top: 2.5vh;
    width: 10vw;
    min-width: 150px;
    height: 30vh;
    
    border-radius: 5px;

    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
}

.card-date {
    margin-top: 5px;
    padding: 5px;
    font-size: 14px;
    color: white;
}

.results-button {
    background-color: white;
    border: none;
    border-radius: 5px;
    outline: none;
    width: 60%;
    height: 30px;
    font-size: 10px;
}

.card-separator {
    height: 1px;
    color: white;
    background-color: white;
    width: 7.5vw;
    margin-left: 1.25vw;
}

.description {
    margin: 5px;
    padding: 5px;
    color: white;
    font-size: 12px;
}

.results-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 0;
}

#event-1 {
    background-color: #5F00D8;
}

#event-2 {
    background-color: #45009C;
}

#event-3 {
    background-color: #46008B;
}

.section-heading {
    margin: 0;
    padding: 0;
}