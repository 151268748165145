div.div-page-wrapper {
    height: 100vh;
    width: 100%;

    margin: 0;
    padding: 0;

    background-image: url("../../res/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
}

div.div-section {
    float: left;

    height: 100%;
    width: 37.5%;

    margin: 0;
    padding: 0;

    overflow-y: auto; 
}

div.div-ticket {
    width: 90%;
    height: auto;

    margin: 0 0 5% 0;
    padding: 0;

    background-color: rgb(255, 255, 255);

    overflow-y: auto;

    transition: 0.25s;
}

div.div-ticket-content-container {
    float: left;

    width: 80%;
    height: 100%;

    margin: 0;
    padding: 5%;
}

div#div-ad-button {
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    cursor: pointer;
}

div#div-ad-button p.plus{
    height: 10vh;
    font-size: 7vh;
}

div.div-ticket-cancel-container {
    float: left;

    width: 20%;
    height: 100%;

    text-align: center;

    margin: 0;
    padding: 5%;
}

div#div-tournament-description-section {
    margin: 0 5%;

    width: 52.5%;

    background-color: rgb(255, 255, 255);
}

div#div-checkout {
    padding: 5%;

    font-family: 'Roboto Thin', sans-serif;
    font-size: 1em;
    font-weight: 100; 
}

h1.h1-section-header {
    height: 5%;
    width: 100%;

    font-family: 'Roboto Thin', sans-serif;
    font-weight: 100;

    text-align: center;
}

p.p-ticket-heading {
    margin: 2.5% 0%;

    font-family: 'Roboto Thin', sans-serif;
    font-size: 1em;
    font-weight: 100;
}

p.p-ticket-label {
    margin: 2.5% 0%;

    font-family: 'Roboto Medium', sans-serif;
    font-size: 1em;
    font-weight: 500;
}

p.p-ticket-cost {
    text-align: right;
    margin: 2.5%;
    letter-spacing: 0.05em;
}

p.p-warning {
    margin: 2.5% 0%;

    color: rgb(255, 0, 0);
    font-family: 'Roboto Thin', sans-serif;
    font-size: 0.75em;
    font-weight: 100;
}

span.span-cost-dollar-sign {
    color: rgb(191, 191, 191);
    font-family: 'Roboto Medium', sans-serif;
    font-size: 1.5em;
    font-weight: 1000;

    vertical-align: 37.5%;
}

span.span-cost-integer {
    color: rgb(0, 0, 0);
    font-family: 'Roboto Medium', sans-serif;
    font-size: 2.5em;
    font-weight: 1000;
}

span.span-cost-decimal {
    color: rgb(191, 191, 191);
    font-family: 'Roboto Medium', sans-serif;
    font-size: 2.5em;
    font-weight: 1000;
}

button.button-cancel-button {
    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    
    transform: rotate(90deg);
}

select.select-standard {
    width: 60%;
    height: 10%;

    margin: 0;
    padding: 2.5% 5%;

    font-family: 'Roboto Thin', sans-serif;
    font-size: 1.25em;
    font-weight: 100;
}

option.option-standard {
    width: 50%;
    height: 10%;

    margin: 0;
    padding: 0;

    font-family: 'Roboto Thin', sans-serif;
    font-size: 1.25em;
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto Medium';
    src: url(../../assets/fonts/Roboto-Medium.ttf);
}

@font-face {
    font-family: 'Roboto Thin';
    src: url(../../assets/fonts/Roboto-Thin.ttf);
}

/* div.div-section {
    float: left;
    margin: 0% 2.5%;
    width: 45%;
    height: 100vh; 
    overflow-y: auto; 
}

div.div-tournament-description {
    width: 100%;
    margin: 2.5% 0;
    padding: 5% 5%;
    height: 95%;
    background-color: white;
    overflow-y: auto;
}

div.div-ticket {
    width: 100%;
    margin: 2.5% 0;
    padding: 2.5% 2.5%;
    background-color: rgb(220, 220, 220);
    border-radius: 2.5%;
}

div.div-ticket-section {
    float: left;
    margin: 2.5% 5%;
    height: 20%;
    width: 90%;
}

div#div-page-wrapper {
    height: 100vh;
    background-image: url("../../res/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
}

p.p-check, p.p-description {
    font-size: 2vh;
    margin: 1vh 0;
}

div.div-hr {
    margin: 0% 0%;
    height: 1px;
    width: 100%;
    background-color: black;
}

div.child-container {
    padding-left: 2.5vw;
}

h1.h1-section-header {
    margin: 0;
    font-size: 3.5vh;
}

h1.h1-header {
    margin: 0.5vh 0;
    font-size: 24px;
}

h2.h2-large {
    margin: 0.5vh 0;
    font-size: 2.25vh;
}

h2.h2-standard {
    margin: 0.25vh 0;
    font-size: 2vh;
}

h2.h2-important {
    margin: 0.25vh 0;
    font-size: 1vh;
    color: #FF0000;
}

div.div-spacer {
    width: 100%;
    height: 2.5vh;
}

div#div-checkout-container {
    margin: 5vh;
    padding: 1vh;
    border: 1px solid black;
}

div#div-submit-button-container {
    width: 100%;
    height: 4vh;
    text-align: center;
}

span.span-spacer {
    width: 5vw;
    height: 1vh;
}

select.select-standard {
    margin: 0.25vh 0;
}

option.option-standard {
    margin: 0.25vh;
}

input.input-submit {
    width: 10vw;
    height: 4vh;
    outline: none;
    border: none;
    background-color: black;
    color: white;
} */

.page {
    position: relative;
  
    --background: (rgb(134, 134, 134));
    background-image: url("../../res/banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 25vw;
    min-height: 200px;
  }
  
  .form {
    position: relative;
    top: 30px;
    bottom: 20px;
  
    background-color: white;
    margin: auto;
    padding: 50px;
    padding-left: 80px;
    padding-right: 80px;
    width: fit-content;
  }
  
  .form-title {
    color: black;
    margin-bottom: 20px;
  }
  
  .field-row {
    --background: transparent;
    margin-top: 10px;
  }
  
  .form-input-item {
    --background: white;
    padding: 0;
  }
  
  .form-input {
    --color: black;
    --placeholder-color: black;
    --placeholder-opacity: 1;
  }
  
  .form-submit {
    margin: auto;
    margin-top: 20px;
    width: 200px;
  }
  
  .form-note {
    margin: auto;
    margin-top: 10px;
    width: 200px;
    font-size: small;
  }