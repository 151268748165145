.body{
    background-color: rgb(200, 170, 214);
    height: 100vh;
}

.body-nice{
    width: 100vw;
    height:46vh;
    background-color: #F7D6D6;
}
.NiceToMeetYou{
    width: 50vw;
    padding: 20px;
    font-family: sans-serif;
    font-size: 240%;
    color:rgb(62, 25, 110);
}

.IntroP{
    margin-top: 5px;
    width: 40vw;
    font-family: sans-serif;
    line-height: 140%;
    font-size: 40%;
    color:rgb(62, 25, 110);
}

.OurMission{
    height: 100vh;
    width: 50vw;
    padding: 20px;
    margin-top: 13vh;
    margin-left: 30vw;
    font-family: sans-serif;
    font-size: 240%;
    color:rgb(62, 25, 110);
}

.MissionP{
    width: 40vw;
    font-family: sans-serif;
    line-height: 140%;
    font-size: 40%;
    color:rgb(62, 25, 110);
}

.wave {
    margin-top: 46vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.5px);
    height: 15vh;
    transform: rotateY(180deg);
}

.wave .shape-fill {
    fill: #F7D6D6;
}