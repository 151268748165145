.div-content-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-payment-message {
    margin: 2.5%;

    font-family: "Roboto Medium", Helvetica, sans-serif;
    font-size: 2.5vh;
}

@font-face {
    font-family: 'Roboto Medium';
    src: url(../../assets/fonts/Roboto-Medium.ttf);
  }
  
  @font-face {
    font-family: 'Roboto Thin';
    src: url(../../assets/fonts/Roboto-Thin.ttf);
  }