.profile {
  margin: 20px;
}

.p-img {
  position: relative;
  margin: 5% 20%;
  width: 60%;
}

.card {
  margin: 20px auto;
  padding: 20px;
  border-radius: 5%;
  background-color: #a0c3fc;
}

.row {
  margin-top: 20px;
}

.name {
  font-size: 36px;
  font-weight: bold;
}

.email {
  font-size: 18px;
  text-align: center;
}

.username {
  font-size: 14px;
  text-align: end;
}

.meta {
  font-size: 12px;
}
/* 
.children {
  width: auto;
  align-items: center;
  display: flex;
  position: relative;
  background-color: #ffe07a;
} */
