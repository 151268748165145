.admin-list-wrapper {
    margin: 0;
    padding: 0;

    height: 100%;

    float: left;
}

.list-wrapper {
    margin: 2rem;

    background-color: #ffffff;

    border-radius: 0.5rem;
}

.tournament-divider {
    width: calc(100% - 4rem);
    height: 0.5px;
    margin: 0 2rem;

    background-color: #dfdfdf;
}

.tournament-item-content {
    width: 100%;
    height: 100%;

    padding: 1rem 2rem;

    transition: 100ms;
}

.tournament-item-wrapper {
    width: 100%;
    height: 100%;

    padding: 0;
    margin: 0;
}

.header {
    margin: 0;
    padding: 2rem;
}

.icon {
    background-color: transparent;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    float: right;
    transition: 0.2s;
    
}

.icon-static {
    background-color: transparent;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    float: right;
    transition: 0.2s;
    
}

.status-txt {
    float: right;
    color: white;
    background-color: black;
    border-radius: 2px;
}

.icon:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.icon:active {
    background-color: rgba(0, 0, 0, 0.2);
}

.star {
    color: red;
}

.div-markdown-container {
    padding: 2rem;
    border: 2px solid black;
}