#wrapper {
    padding: 1rem;
    width: 100%;
}

.IonCard-section {
    margin: 1rem;
    padding: 1rem;
}

p {
    margin: 1rem;
}

.txt {
    height: auto;
}

.txt textarea{
    height: auto
}

.divider{
    width: 100%;
    height: 0.1px;
    margin: 0.5rem 0;
    background-color: #dfdfdf;
}